// Import Element Theme
@import '@simpl/element-theme/src/theme';

// Import Element Components
@import '@simpl/element-ng/simpl-element-ng';

// Import MapGL styles
@import '~mapbox-gl/dist/mapbox-gl.css';

// Import ngx-datatable Bootstrap style
@import '@siemens/ngx-datatable/themes/bootstrap';

body,
app-root,
app-root > * {
  display: flex;
  flex: auto;
  flex-direction: column;
}

router-outlet {
  display: none;
}
